import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class QrCode extends Vue {
  @Prop({ type: String, required: true })
  private readonly value!: string;

  private async mounted() {
    const $el = this.$el as HTMLCanvasElement;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { default: QRious } = await import("qrious");

    new QRious({
      element: $el,
      value: this.value,
      size: 500,
    });
  }
}
